import React from "react";
import "./style.css";

export const RupeeLog = () => {
  return (
    <div className="rupee-log">
      <div className="div">
        <div className="overlap">
          <img className="img" alt="Img" src="/img/6441238f19ec77c3e5ce2969-footer-stars-svg.svg" />
          <img className="img-2" alt="Img" src="/img/6441238f19ec77c3e5ce2969-footer-stars-svg-1.svg" />
          <div className="gradient" />
          <div className="container">
          <div className="finance-is-exciting-wrapper">
          Finance is exciting at RupeeLog
          </div>
          <div className="at-rupeelog-wrapper">
            <p className="at-rupeelog">
              At Rupeelog Financial, we offer a comprehensive suite of technology solutions designed to meet the
              diverse needs of financial markets participants. Whether you're a hedge fund, asset manager, or
              institutional investor, our customisable solutions empower you to stay ahead of the curve.
            </p>
          </div>
        </div>
          <div className="frame">
            <div className="frame-2">
              <div className="heading">
                <p className="advanced-tech">
                  Advanced Tech Solutions <br />
                  for Financial Markets
                </p>
              </div>
              <div className="div-wrapper">
                <p className="text-wrapper">
                  Explore our innovative analytics and AI-driven solutions designed to empower decision-makers, enhance
                  market intelligence, and drive sustainable growth.
                </p>
              </div>
            </div>
            <div className="background-wrapper">
              <div className="background">
              <div className="link">
                <div className="container-2">
                  <a href="mailto:contact@rupeelog.com" className="text-wrapper-2">GET IN TOUCH</a>
                </div>
              </div>
              <div className="horizontal-divider">
                      <img alt="Horizontal Glow" src="/img/Horiz-glow.svg" />
              </div>
                {/* <div className="horizontal-divider-2" /> */}
              </div>
            </div>
          </div>
          <div className="group">
            <div className="overlap-group">
              <img className="img-4" alt="Img" src="/img/6441238f19ec77c3e5ce2969-footer-stars-svg-3.svg" />
              <div className="container-wrapper">
                <div className="container-3">
                  <div className="overlay-shadow">
                    <div className="container-4">
                      <div className="div-wrapper-2">
                        <div className="text-wrapper-3">Company Overview</div>
                      </div>
                      <div className="container-5">
                        <p className="p">
                          Rupeelog provides technology solutions for financial markets. With a team of industry experts
                          and cutting-edge technology, we&#39;re dedicated to helping our clients navigate the
                          complexities of today&#39;s financial landscape
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="overlay-shadow">
                    <div className="container-4">
                      <div className="div-wrapper-2">
                        <div className="text-wrapper-3">Mission and Vision</div>
                      </div>
                      <div className="container-6">
                        <p className="p">
                          Our mission is to empower financial markets participants with advanced technology solutions
                          that drive efficiency, transparency, and profitability. We envision a future where data-driven
                          insights unlock new opportunities and transform decision-making processes.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="overlay-shadow">
                    <div className="container-7">
                      <div className="div-wrapper-2">
                        <div className="text-wrapper-3">Why Choose us</div>
                      </div>
                      <div className="container-5">
                        <p className="p">
                          From our commitment to innovation to our unparalleled industry expertise, discover why leading
                          financial institutions trust us to deliver results.
                        </p>
                      </div>
                    </div>
                    <div className="horizontal-divider-3">
                      <img alt="Horizontal Glow" src="/img/Horiz-glow.svg" />
                    </div>
                    <div className="horizontal-divider-4" />
                  </div>
                  <div className="horizontal-divider-5">
                      <img alt="Horizontal Glow" src="/img/Horiz-glow.svg" />
                    </div>
                </div>
              </div>
              <div className="horizontal-divider-7">
                      <img alt="Horizontal Glow" src="/img/Horiz-glow.svg" />
              </div>
              <div className="horizontal-divider-8" />
            </div>
          </div>
          <img className="banner" alt="Banner" src="/img/banner.svg" />
        </div>
        <div className="overlap-group-wrapper">
          <div className="overlap-group-2">
            <img className="img-5" alt="Img" src="/img/6441238f19ec77c3e5ce2969-footer-stars-svg-2.svg" />
            <div className="frame-3">
              <div className="frame-4">
                <div className="heading-wrapper">
                  <div className="innovative-wrapper">
                    <p className="innovative">
                      Innovative Technology <br />
                      Solutions for Financial Markets
                    </p>
                  </div>
                </div>
              </div>
              <div className="frame-5">
                <div className="frame-6">
                  <div className="background-border">
                    <div className="container-8">
                      <div className="text-wrapper-4">Market Analytics</div>
                      <div className="container-9">
                        <p className="text-wrapper-5">
                          Harness the power of data analytics to gain actionable insights into market trends, risks,{" "}
                          <br />
                          and opportunities.
                        </p>
                      </div>
                    </div>
                    <div className="horizontal-divider-9">
                      <img alt="Horizontal Glow" src="/img/Horiz-glow.svg" />
                    </div>
                    {/* <div className="horizontal-divider-10" /> */}
                  </div>
                  <div className="background-border-2">
                    <div className="container-8">
                      <div className="text-wrapper-4">AI-driven Trading Systems:</div>
                      <div className="container-9">
                        <p className="text-wrapper-5">
                          Leverage advanced AI algorithms to optimize trading strategies and maximize returns.
                        </p>
                      </div>
                    </div>
                    <div className="horizontal-divider-9">
                      <img alt="Horizontal Glow" src="/img/Horiz-glow.svg" />
                    </div>
                    {/* <div className="horizontal-divider-10" /> */}
                  </div>
                </div>
                <div className="background-border-3">
                  <div className="container-8">
                    <div className="heading-one-simple">Artificial Intelligence</div>
                    <div className="div-wrapper-2">
                      <p className="text-wrapper-5">
                        From machine learning to natural language processing, our AI-powered solutions deliver
                        unparalleled accuracy and efficiency, transforming how financial professionals interact with
                        data.
                      </p>
                    </div>
                  </div>
                  <div className="horizontal-divider-11">
                      <img alt="Horizontal Glow" src="/img/Horiz-glow.svg" />
                  </div>
                  {/* <div className="horizontal-divider-12" /> */}
                </div>
                <div className="background-border-wrapper">
                  <div className="background-border-4">
                    <div className="container-8">
                      <div className="text-wrapper-4">Analytics</div>
                      <div className="div-wrapper-2">
                        <p className="our-analytics">
                          Our analytics platform utilizes cutting-edge data analysis techniques to provide
                          <br />
                          real-time intelligence, enabling informed decision-making across the investment lifecycle.
                        </p>
                      </div>
                    </div>
                    <div className="horizontal-divider-13">
                      <img alt="Horizontal Glow" src="/img/Horiz-glow.svg" />
                    </div>
                    {/* <div className="horizontal-divider-14" /> */}
                  </div>
                </div>
                <div className="background-border-5">
                  <div className="container-10">
                    <div className="heading-one-simple">Risk Management Tools</div>
                    <div className="div-wrapper-2">
                      <p className="text-wrapper-5">
                        Identify and mitigate risks with precision using our sophisticated risk management solutions.
                      </p>
                    </div>
                  </div>
                  <div className="horizontal-divider-13">
                      <img alt="Horizontal Glow" src="/img/Horiz-glow.svg" />
                  </div>
                  {/* <div className="horizontal-divider-16" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-7">
          <div className="background-shadow">
            <div className="frame-8">
              <p className="text-wrapper-6">Get in touch with us</p>
            </div>
            <div className="frame-9">
              <div className="button"></div>
              <a href="mailto:contact@rupeelog.com" className="text-wrapper-a">contact@rupeelog.com</a>
            </div>
          </div>
          <div className="horizontal-border">
            {/* <div className="container-11">
              <div className="container-12">
                <div className="div-wrapper-2">
                  <div className="text-wrapper-8">© 2023 Rupeelog</div>
                </div>
              </div>
              <div className="container-13">
                <div className="link-2">
                  <div className="text-wrapper-8">Privacy Policy</div>
                </div>
                <div className="link-2">
                  <div className="text-wrapper-8">Terms of Use</div>
                </div>
              </div>
            </div> */}
            <a href="https://www.linkedin.com/company/rupeelog-financial-services-pvt.-ltd./" target="_blank" rel="noopener noreferrer">
              <img className="img-3" alt="Container" src="/img/linkedIn.svg" />
            </a>
          </div>
        </div>
        <div className="frame-10">
          <div className="text-wrapper-9">How we Innovate</div>
          <div className="frame-11">
            <div className="frame-12">
              <img className="img-3" alt="Git merge" src="/img/git-merge-1.svg" />
              <div className="frame-13">
                <div className="text-wrapper-4">Methodology</div>
                <p className="at-rupelog-we">
                  At Rupelog, we combine domain expertise with technology innovation to deliver tailored solutions that
                  address our clients&#39; unique challenges. Our iterative approach emphasizes collaboration, agility,
                  and continuous improvement.
                </p>
              </div>
            </div>
            <div className="frame-12">
              <img className="img-3" alt="Git merge" src="/img/git-merge-1-1.svg" />
              <div className="frame-13">
                <div className="text-wrapper-4">Innovation</div>
                <p className="innovation-is-at-the">
                  Innovation is at the heart of everything we do. From exploring emerging technologies to refining our
                  algorithms, we&#39;re committed to staying ahead of the curve and driving positive change in the
                  industry.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container-14">
            <div className="container-15">
              <div className="overlap-group-3">
                <div className="heading-2">
                  <div className="text-wrapper-10">Career Opportunities at Rupeelog</div>
                </div>
                <img className="container-16" alt="Container" src="/img/container-1.svg" />
                <p className="text-wrapper-11">
                  Explore our current job openings and apply online today, discover why Rupeelog Financial is a great
                  place to work.
                </p>
                <div className="background-2">
                  <a href="https://folkflow.com/co/company-1452150778" className="link" target="_blank" rel="noopener noreferrer">
                    <div className="container-2">
                      <div className="text-wrapper-2">Apply Now</div>
                    </div>
                    <img className="arrow-right" alt="Arrow right" src="/img/arrow-right-1.svg" />
                  </a>
                  <div className="horizontal-divider-18">
                      <img alt="Horizontal Glow" src="/img/Horiz-glow.svg" />
                  </div>
                  {/* <div className="horizontal-divider-19" /> */}
                </div>
                <div className="background-3">
                  <a href="mailto:contact@rupeelog.com" target="_blank" rel="noopener noreferrer">
                    <img alt="Container"  className="web-email" src="/img/Web-email-button.svg" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};